<template>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="flex flex-col gap-y-5">
      <li>
        <ul role="list" class="space-y-1">
          <li v-for="item in navigation" :key="item.name">
            <nuxt-link
              :to="item.href"
              :class="[
                item.current
                  ? 'bg-gray-50 text-primary-400'
                  : 'text-gray-700 hover:bg-gray-50',
                'group flex gap-x-3 rounded-md px-2 py-[0.35rem] text-sm leading-4 items-center',
              ]"
              :data-cy="item.dataCy"
            >
              <component
                :is="item.icon"
                :class="[
                  item.current ? 'text-primary-400' : 'text-gray-400',
                  'h-4 w-4 shrink-0',
                ]"
                aria-hidden="true"
              />
              {{ item.name }}
              <span
                v-if="item.indicatorNumber > 0"
                class="inline-flex items-center rounded-md bg-red-500 px-2 py-0 text-xs font-medium text-white ml-auto"
                >{{ getDisplayIndicatorNumber(item.indicatorNumber) }}</span
              >
            </nuxt-link>
          </li>
          <Menu
            v-if="canManage"
            v-tooltip="'Create new report/board/folder'"
            as="li"
            class="relative text-gray-700 hover:bg-gray-50 rounded-md px-2 py-[0.35rem] text-sm leading-4 items-center cursor-pointer"
            @click.stop
          >
            <MenuButton
              class="inline-flex items-center gap-x-3 w-full"
              data-cy="sidebar-nav-create-button"
            >
              <PlusCircleIcon
                class="h-4 w-4 text-gray-400 shrink-0"
                aria-hidden="true"
              />Create
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      type="button"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                        'inline-flex px-4 py-2 text-xs w-full',
                      ]"
                      data-cy="sidebar-nav-create-topcreative-report-button"
                      @click="createReport('topcreatives', null)"
                    >
                      <component
                        :is="getIconComponent('topcreatives')"
                        :class="[
                          'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                          getIconColor('topcreatives'),
                        ]"
                        aria-hidden="true"
                      /><span>Top performing</span
                      ><InformationCircleIcon
                        v-tooltip="'Identify your best performers'"
                        class="h-4 w-4 shrink-0 ml-auto"
                      />
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      type="button"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                        'inline-flex px-4 py-2 text-xs w-full',
                      ]"
                      data-cy="sidebar-nav-create-comparison-report-button"
                      @click="createReport('comparison', null)"
                    >
                      <component
                        :is="getIconComponent('comparison')"
                        :class="[
                          'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                          getIconColor('comparison'),
                        ]"
                        aria-hidden="true"
                      /><span>Comparative analysis</span
                      ><InformationCircleIcon
                        v-tooltip="'Compare different creative groups'"
                        class="h-4 w-4 shrink-0 ml-auto"
                      />
                    </a>
                  </MenuItem>
                  <MenuItem v-if="hasLaunchReport" v-slot="{ active }">
                    <a
                      href="#"
                      type="button"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                        'inline-flex px-4 py-2 text-xs w-full',
                      ]"
                      data-cy="sidebar-nav-create-launch-report-button"
                      @click.prevent="createLaunchReport(null)"
                    >
                      <component
                        :is="getIconComponent('launchReport')"
                        :class="[
                          'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                          getIconColor('launchReport'),
                        ]"
                        aria-hidden="true"
                      /><span>Recently launched</span
                      ><InformationCircleIcon
                        v-tooltip="'See recently launched creatives'"
                        class="h-4 w-4 shrink-0 ml-auto"
                      />
                    </a>
                  </MenuItem>
                  <MenuItem v-if="hasTestingLogReport" v-slot="{ active }">
                    <a
                      href="#"
                      type="button"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                        'inline-flex px-4 py-2 text-xs w-full',
                      ]"
                      data-cy="sidebar-nav-create-testing-log-button"
                      @click.prevent="createTestingLogReport(null)"
                    >
                      <component
                        :is="getIconComponent('testingLog')"
                        :class="[
                          'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                          getIconColor('testingLog'),
                        ]"
                        aria-hidden="true"
                      /><span>Testing Log</span
                      ><InformationCircleIcon
                        v-tooltip="'Identify winners in your naming convention'"
                        class="h-4 w-4 shrink-0 ml-auto"
                      />
                    </a>
                  </MenuItem>
                  <MenuItem v-if="hasInspirationBoard" v-slot="{ active }">
                    <a
                      href="#"
                      type="button"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                        'inline-flex px-4 py-2 text-xs w-full',
                      ]"
                      data-cy="sidebar-nav-create-board-button"
                      @click="createBoard(null)"
                    >
                      <component
                        :is="getIconComponent('board')"
                        :class="[
                          'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                          getIconColor('board'),
                        ]"
                        aria-hidden="true"
                      /><span>Inspiration Board</span
                      ><InformationCircleIcon
                        v-tooltip="
                          'Save your favorite creatives from ads library'
                        "
                        class="h-4 w-4 shrink-0 ml-auto"
                      />
                    </a>
                  </MenuItem>
                </div>
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      type="button"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                        'inline-flex px-4 py-2 text-xs w-full',
                      ]"
                      data-cy="sidebar-nav-create-folder-button"
                      @click="createFolder"
                    >
                      <FolderPlusIcon
                        class="h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded text-gray-500"
                      /><span>Folder</span
                      ><InformationCircleIcon
                        v-tooltip="
                          'Organize your reports and boards in folders'
                        "
                        class="h-4 w-4 shrink-0 ml-auto"
                      />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </ul>
      </li>
    </ul>
    <ul role="list" class="flex flex-1 flex-col gap-y-2 mt-3">
      <SidebarSubnavigation
        :navigation="
          getFolderSubnavigation({
            folderId: null,
            reports: props.reports,
            boards: props.boards,
            testingLogs: props.testingLogReports,
            launchReports: props.launchReports,
          })
        "
        :can-manage="canManage"
        :with-folders="true"
        data-cy="sidebar-nav-files-no-folder"
        @delete="deleteFile"
        @share="shareFile"
        @drag="onDragFiles($event, null)"
      />
      <VueDraggableNext
        tag="ul"
        group="folders"
        class="flex flex-1 flex-col gap-y-2"
        role="list"
        ghost-class="ghost"
        :disabled="!canManage"
        :model-value="sortedFolders"
        @change="onDragFolders"
      >
        <li
          v-for="folder in sortedFolders"
          :key="folder.id"
          class="space-y-1 -mx-4"
          data-cy="sidebar-nav-folder-wrapper"
        >
          <ul class="px-2">
            <li
              class="text-gray-400 group flex gap-x-1 px-2 py-[0.35rem] text-xs leading-4 items-center rounded-md bg-transparent hover:bg-gray-50 cursor-pointer"
              @click="toggleFolder(folder.id)"
            >
              <div
                class="rounded-md p-1 bg-transparent hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                data-cy="sidebar-nav-folder-toggle"
              >
                <ChevronDownIcon
                  :class="[
                    'h-4 w-4',
                    isFolderOpen(folder.id) ? 'rotate-0' : '-rotate-90',
                  ]"
                  aria-hidden="true"
                />
              </div>
              <span
                v-tooltip="'Double click to rename folder'"
                class="inline-flex items-center hover:bg-gray-100"
                data-cy="sidebar-nav-folder-title"
                @click.stop="onRenameFolder(folder.id)"
                @dblclick="onRenameFolder(folder.id)"
                >{{ folder.title }}</span
              >
              <Menu
                v-if="canManage"
                v-tooltip="'Rename, duplicate, and more...'"
                as="div"
                class="relative ml-auto"
                @click.stop
              >
                <MenuButton
                  class="rounded-md p-1 bg-transparent hover:bg-gray-200 text-white group-hover:text-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                  data-cy="sidebar-nav-folder-menu-button"
                >
                  <EllipsisHorizontalIcon class="h-4 w-4" aria-hidden="true" />
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          data-cy="sidebar-nav-folder-rename-button"
                          @click.prevent="onRenameFolder(folder.id)"
                        >
                          <PencilSquareIcon
                            class="h-4 w-4 shrink-0 mr-2"
                          /><span>Rename Folder</span>
                        </a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          @click.prevent="onDuplicateFolder(folder.id)"
                        >
                          <DocumentDuplicateIcon
                            class="h-4 w-4 shrink-0 mr-2"
                          /><span>Duplicate Folder</span>
                        </a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          data-cy="sidebar-nav-folder-delete-button"
                          @click.prevent="onDeleteFolder(folder.id)"
                        >
                          <TrashIcon class="h-4 w-4 shrink-0 mr-2" /><span
                            >Delete Folder</span
                          >
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
              <Menu
                v-if="canManage"
                v-tooltip="'Create new report/board'"
                as="div"
                class="relative"
                @click.stop
              >
                <MenuButton
                  class="rounded-md p-1 bg-gray-50 text-gray-700 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                  data-cy="sidebar-nav-folder-create-button"
                >
                  <PlusIcon class="h-4 w-4" aria-hidden="true" />
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          data-cy="sidebar-nav-folder-create-topcreative-report-button"
                          @click.prevent="
                            createReport('topcreatives', folder.id)
                          "
                        >
                          <component
                            :is="getIconComponent('topcreatives')"
                            :class="[
                              'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                              getIconColor('topcreatives'),
                            ]"
                            aria-hidden="true"
                          /><span>Top performing</span
                          ><InformationCircleIcon
                            v-tooltip="'Identify your best performers'"
                            class="h-4 w-4 shrink-0 ml-auto"
                          />
                        </a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          data-cy="sidebar-nav-folder-create-comparison-report-button"
                          @click.prevent="createReport('comparison', folder.id)"
                        >
                          <component
                            :is="getIconComponent('comparison')"
                            :class="[
                              'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                              getIconColor('comparison'),
                            ]"
                            aria-hidden="true"
                          /><span>Comparative analysis</span
                          ><InformationCircleIcon
                            v-tooltip="'Compare different creative groups'"
                            class="h-4 w-4 shrink-0 ml-auto"
                          />
                        </a>
                      </MenuItem>
                      <MenuItem v-if="hasLaunchReport" v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          data-cy="sidebar-nav-folder-create-launch-report-button"
                          @click.prevent="createLaunchReport(folder.id)"
                        >
                          <component
                            :is="getIconComponent('launchReport')"
                            :class="[
                              'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                              getIconColor('launchReport'),
                            ]"
                            aria-hidden="true"
                          /><span>Recently launched</span
                          ><InformationCircleIcon
                            v-tooltip="'See recently launched creatives'"
                            class="h-4 w-4 shrink-0 ml-auto"
                          />
                        </a>
                      </MenuItem>
                      <MenuItem v-if="hasTestingLogReport" v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          data-cy="sidebar-nav-folder-create-testing-log-button"
                          @click.prevent="createTestingLogReport(folder.id)"
                        >
                          <component
                            :is="getIconComponent('testingLog')"
                            :class="[
                              'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                              getIconColor('testingLog'),
                            ]"
                            aria-hidden="true"
                          /><span>Testing Log</span
                          ><InformationCircleIcon
                            v-tooltip="
                              'Identify winners in your naming convention'
                            "
                            class="h-4 w-4 shrink-0 ml-auto"
                          />
                        </a>
                      </MenuItem>
                      <MenuItem v-if="hasInspirationBoard" v-slot="{ active }">
                        <a
                          href="#"
                          type="button"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-700'
                              : 'text-gray-700',
                            'inline-flex px-4 py-2 text-xs w-full',
                          ]"
                          data-cy="sidebar-nav-folder-create-board-button"
                          @click.prevent="createBoard(folder.id)"
                        >
                          <component
                            :is="getIconComponent('board')"
                            :class="[
                              'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
                              getIconColor('board'),
                            ]"
                            aria-hidden="true"
                          /><span>Inspiration Board</span
                          ><InformationCircleIcon
                            v-tooltip="
                              'Save your favorite creatives from ads library'
                            "
                            class="h-4 w-4 shrink-0 ml-auto"
                          />
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </li>
            <SidebarSubnavigation
              v-if="isFolderOpen(folder.id)"
              :navigation="
                getFolderSubnavigation({
                  folderId: folder.id,
                  reports: props.reports,
                  boards: props.boards,
                  testingLogs: props.testingLogReports,
                  launchReports: props.launchReports,
                })
              "
              :can-manage="canManage"
              :with-folders="true"
              class="ml-2"
              data-cy="sidebar-nav-files-of-folder"
              @delete="deleteFile"
              @share="shareFile"
              @drag="onDragFiles($event, folder.id)"
            />
          </ul>
        </li>
      </VueDraggableNext>
      <SidebarProfileMenu />
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { PropType, FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
import {
  PlusIcon,
  InformationCircleIcon,
  ChevronDownIcon,
  TrashIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  FolderPlusIcon,
} from "@heroicons/vue/24/outline";
import {
  EllipsisHorizontalIcon,
  PlusCircleIcon,
} from "@heroicons/vue/20/solid";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { VueDraggableNext } from "vue-draggable-next";
import {
  AnyCreativeReport,
  CreativeReportType,
} from "~/types/AnyCreativeReport";
import { FilesSortOrderEntry, Folder } from "~/types/FolderStructure";
import { InspirationBoard } from "~/types/InspirationBoard";
import { TestingLogReport } from "~/types/TestingLog";
import { LaunchReport } from "~/types/LaunchReport";

type NavigationElement = {
  id: string;
  name: string;
  href: string;
  icon: string;
  current: boolean;
  duplicateHref: string;
  type: string;
};

const props = defineProps({
  navigation: {
    type: Array as PropType<
      {
        name: string;
        href: string;
        icon: FunctionalComponent<HTMLAttributes & VNodeProps, {}, any>;
        current: boolean;
        indicatorNumber: number;
        dataCy: string;
      }[]
    >,
    required: true,
    default: () => [],
  },
  folders: {
    type: Array as PropType<Folder[]>,
    required: true,
    default: () => [],
  },
  canManage: {
    type: Boolean,
    required: true,
    default: false,
  },
  reports: {
    type: Array as PropType<AnyCreativeReport[]>,
    required: true,
    default: () => [],
  },
  boards: {
    type: Array as PropType<InspirationBoard[]>,
    required: true,
    default: () => [],
  },
  testingLogReports: {
    type: Array as PropType<TestingLogReport[]>,
    required: true,
    default: () => [],
  },
  launchReports: {
    type: Array as PropType<LaunchReport[]>,
    required: true,
    default: () => [],
  },
  hasTestingLogReport: {
    type: Boolean,
    required: true,
  },
  hasLaunchReport: {
    type: Boolean,
    required: true,
  },
  hasInspirationBoard: {
    type: Boolean,
    required: true,
  },
});

const { getIconColor, getIconComponent } = useSidebar();

const getDisplayIndicatorNumber = (indicatorNumber: number) =>
  indicatorNumber > 99 ? "99+" : indicatorNumber;

const sortedFolders = computed(() =>
  [...props.folders].sort((a, b) => a.folderSortOrder - b.folderSortOrder),
);

const folderStructureStore = useFolderStructureStore();

const { getFolderSubnavigation, folderToFolderStructure } = useSidebar();

const isFolderOpen = (folderId: number) =>
  props.folders
    .filter((f) => f.isOpen)
    .map((f) => f.id)
    .includes(folderId);

const emit = defineEmits([
  "create:report",
  "delete:report",
  "share:report",
  "create:board",
  "delete:board",
  "share:board",
  "create:testinglog:report",
  "delete:testinglog:report",
  "share:testinglog:report",
  "create:launch:report",
  "delete:launch:report",
  "share:launch:report",
  "toggle:folder",
  "create:folder",
  "delete:folder",
  "rename:folder",
  "duplicate:folder",
]);

const toggleFolder = (folderId: number) => {
  emit("toggle:folder", folderId);
};

const createFolder = () => {
  emit("create:folder");
};

const onDeleteFolder = (folderId: number) => {
  emit("delete:folder", folderId);
};

const deleteFile = (file: { id: string; type: string }) => {
  if (file.type === "board") {
    emit("delete:board", file);
  } else if (file.type === "testingLog") {
    emit("delete:testinglog:report", file);
  } else if (file.type === "launchReport") {
    emit("delete:launch:report", file);
  } else {
    emit("delete:report", file);
  }
};

const shareFile = (file: { id: string; type: string }) => {
  if (file.type === "board") {
    emit("share:board", file);
  } else if (file.type === "testingLog") {
    emit("share:testinglog:report", file);
  } else if (file.type === "launchReport") {
    emit("share:launch:report", file);
  } else {
    emit("share:report", file);
  }
};

const createReport = (type: CreativeReportType, folderId: number | null) => {
  emit("create:report", { type, folderId });
};

const createBoard = (folderId: number | null) => {
  emit("create:board", folderId);
};

const createTestingLogReport = (folderId: number | null) => {
  emit("create:testinglog:report", folderId);
};

const createLaunchReport = (folderId: number | null) => {
  emit("create:launch:report", folderId);
};

const { getReportId, getReportType } = useAnyCreativeReport();

const onDragFolders = async (evt: {
  added?: { element: Folder; newIndex: number };
  removed?: { element: Folder; oldIndex: number };
  moved?: { element: Folder; oldIndex: number; newIndex: number };
}) => {
  // Base folder structure before any changes
  const folderStructure = sortedFolders.value.map((folder) =>
    folderToFolderStructure({
      folder,
      boards: props.boards,
      reports: props.reports,
      testingLogs: props.testingLogReports,
      launchReports: props.launchReports,
    }),
  );

  // Folders can only be moved within the same level
  const evtMoved = evt.moved;
  if (evtMoved) {
    // Change folderSortOrder in folder
    const movedFolderIdx = folderStructure.findIndex(
      (folder) => folder.folderId === evtMoved.element.id,
    );
    if (movedFolderIdx === -1) {
      return;
    }
    const movedFolder = folderStructure[movedFolderIdx];
    folderStructure.splice(movedFolderIdx, 1);
    folderStructure.splice(evtMoved.newIndex, 0, movedFolder);
    folderStructure.forEach((folder, idx) => {
      folder.folderSortOrder = idx;
    });
    await folderStructureStore.updateFolderStructure(folderStructure);
  }
};

const onDragFiles = async (
  evt: {
    added?: { element: NavigationElement; newIndex: number };
    removed?: { element: NavigationElement; oldIndex: number };
    moved?: { element: NavigationElement; oldIndex: number; newIndex: number };
  },
  folderId: number | null,
) => {
  // Folder structure folder has same ordering as getFolderSubnavigation
  const folderStructure = sortedFolders.value.map((folder) =>
    folderToFolderStructure({
      folder,
      boards: props.boards,
      reports: props.reports,
      testingLogs: props.testingLogReports,
      launchReports: props.launchReports,
    }),
  );

  const evtMoved = evt.moved;
  if (evtMoved && typeof folderId === "number") {
    await moveFileInsideFolder({ folderId, folderStructure, evtMoved });
  }

  const evtAdded = evt.added;
  if (evtAdded) {
    // File is added to folder and removed from another folder
    // Change fileSortOrder in folder
    const addedToFolderIdx = sortedFolders.value.findIndex(
      (folder) => folder.id === folderId,
    );
    const addedToFolder = folderStructure[addedToFolderIdx];
    if (addedToFolder == null) {
      return;
    }

    // Removed from folder that contains the file
    const removedFromFolderIdx = folderStructure.findIndex((folder) =>
      folder.files.some((file) => matchFileAndElement(file, evtAdded.element)),
    );
    const removedFromFolder = folderStructure[removedFromFolderIdx];
    if (removedFromFolder == null) {
      dragFileFromNoFileToFolder({ addedToFolder, evtAdded });
      return;
    }

    await dragFileFromOneFolderToAnother({
      addedToFolder,
      removedFromFolder,
      evtAdded,
    });
  }
};

const moveFileInsideFolder = async (dto: {
  folderId: number;
  folderStructure: Array<ReturnType<typeof folderToFolderStructure>>;
  evtMoved: { element: NavigationElement; oldIndex: number; newIndex: number };
}) => {
  // File is moved within folder
  // Change fileSortOrder in folder
  const { folderId, folderStructure, evtMoved } = dto;
  const folderIdx = sortedFolders.value.findIndex(
    (folder) => folder.id === folderId,
  );
  const folder = folderStructure[folderIdx];

  if (folder == null) {
    return;
  }

  const movedFileIdx = folder.files.findIndex((file) =>
    matchFileAndElement(file, evtMoved.element),
  );

  if (movedFileIdx === -1) {
    return;
  }

  const movedFile = folder.files[movedFileIdx];

  // Place moved file in new position
  folder.files.splice(movedFileIdx, 1);
  folder.files.splice(evtMoved.newIndex, 0, movedFile);

  // Set sort order of each file as index in array
  folder.files.forEach((file, idx) => {
    file.fileSortOrder = idx;
  });

  // Update only the touched folder
  await folderStructureStore.updateFolderStructure([folder]);
};

const dragFileFromOneFolderToAnother = async (dto: {
  addedToFolder: ReturnType<typeof folderToFolderStructure>;
  removedFromFolder: ReturnType<typeof folderToFolderStructure>;
  evtAdded: { element: NavigationElement; newIndex: number };
}) => {
  const { addedToFolder, removedFromFolder, evtAdded } = dto;

  // Remove file from removedFromFolder
  const removedFileIdx = removedFromFolder.files.findIndex((file) =>
    matchFileAndElement(file, evtAdded.element),
  );

  if (removedFileIdx === -1) {
    return;
  }

  const removedFile = removedFromFolder.files[removedFileIdx];
  removedFromFolder.files.splice(removedFileIdx, 1);

  // Set sort order of each file as index in array
  removedFromFolder.files.forEach((file, idx) => {
    file.fileSortOrder = idx;
  });

  // Place added file in new position
  addedToFolder.files.splice(evtAdded.newIndex, 0, removedFile);

  // Set sort order of each file as index in array
  addedToFolder.files.forEach((file, idx) => {
    file.fileSortOrder = idx;
  });

  // Update both folders
  await folderStructureStore.updateFolderStructure([
    addedToFolder,
    removedFromFolder,
  ]);
};

const dragFileFromNoFileToFolder = async (dto: {
  addedToFolder: ReturnType<typeof folderToFolderStructure>;
  evtAdded: { element: NavigationElement; newIndex: number };
}) => {
  const { addedToFolder, evtAdded } = dto;

  const fileMaybe = elementToFile(evtAdded.element);

  if (fileMaybe == null) {
    return;
  }

  // Place added file in new position
  addedToFolder.files.splice(evtAdded.newIndex, 0, fileMaybe);

  // Set sort order of each file as index in array
  addedToFolder.files.forEach((file, idx) => {
    file.fileSortOrder = idx;
  });

  // Update only the touched folder
  await folderStructureStore.updateFolderStructure([addedToFolder]);
};

const matchFileAndElement = (
  file: FilesSortOrderEntry & {
    uuid: string;
  },
  element: NavigationElement,
) => {
  return (
    (file.type === "inspiration_board" && file.uuid === element.id) ||
    (file.type === "testing_log_report" && file.uuid === element.id) ||
    (file.type === "launch_report" && file.uuid === element.id) ||
    (file.type === "creative_reporting_report" &&
      file.id === getReportId(element.id) &&
      getReportType(element.id) === "topcreatives") ||
    (file.type === "creative_comparison_report" &&
      file.id === getReportId(element.id) &&
      getReportType(element.id) === "comparison")
  );
};

const elementToFile = (
  element: NavigationElement,
):
  | (FilesSortOrderEntry & {
      uuid: string;
    })
  | null => {
  if (element.type === "board") {
    const board = props.boards.find((board) => board.uuid === element.id);
    return board != null
      ? {
          id: board.id,
          uuid: board.uuid,
          type: "inspiration_board",
          fileSortOrder: 0,
        }
      : null;
  }

  if (element.type === "testingLog") {
    const report = props.testingLogReports.find(
      (report) => report.uuid === element.id,
    );
    return report != null
      ? {
          id: report.id,
          uuid: report.uuid,
          type: "testing_log_report",
          fileSortOrder: 0,
        }
      : null;
  }

  if (element.type === "launchReport") {
    const report = props.launchReports.find(
      (report) => report.uuid === element.id,
    );
    return report != null
      ? {
          id: report.id,
          uuid: report.uuid,
          type: "launch_report",
          fileSortOrder: 0,
        }
      : null;
  }

  if (element.type === "topcreatives") {
    const report = props.reports.find(
      (report) =>
        report.id === getReportId(element.id) &&
        getReportType(element.id) === "topcreatives",
    );
    return report != null
      ? {
          id: report.id,
          uuid: report.uuid,
          type: "creative_reporting_report",
          fileSortOrder: 0,
        }
      : null;
  }

  if (element.type === "comparison") {
    const report = props.reports.find(
      (report) =>
        report.id === getReportId(element.id) &&
        getReportType(element.id) === "comparison",
    );
    return report != null
      ? {
          id: report.id,
          uuid: report.uuid,
          type: "creative_comparison_report",
          fileSortOrder: 0,
        }
      : null;
  }

  return null;
};

const onRenameFolder = (folderId: number) => {
  emit("rename:folder", folderId);
};

const onDuplicateFolder = (folderId: number) => {
  emit("duplicate:folder", folderId);
};
</script>

<style scoped lang="scss">
.ghost {
  @apply bg-gray-100 border border-dashed border-gray-300 rounded-md;
}
</style>
